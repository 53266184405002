import React, { useCallback, useState } from "react"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/pro-light-svg-icons/faAngleRight"
import { faFileAlt } from "@fortawesome/pro-light-svg-icons/faFileAlt"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { TreeItem } from "@atlaskit/tree"
import { ItemId } from "@atlaskit/tree/types"
import "./Category.scss"
import { KnowledgeBasePermittedAction } from "../../models/knowledgeBasePermission"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { useSelector } from "react-redux"
import { selectCurrentArticle } from "../../store/knowledgeBase/selectors"
import { testId } from "../../utility/tests/testId"
import knowledgeBaseController from "../../api/controllers/knowledgeBase"
import { useAppSelector } from "../../store/hooks"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import truncate from "lodash/truncate"

const tNamespace = "knowledgeBase:"
const tooltipDelay = { show: 450, hide: 0 }
const categoryTitleMaxLength = 40

export type OnTitleClick = (categoryItem: TreeItem, catalogCode?: string, categoryCode?: string) => void

export interface CategoryProps {
    item: TreeItem
    onExpand: (itemId: ItemId) => void
    onCollapse: (itemId: ItemId) => void
    onTitleClick?: OnTitleClick
    onCanEdit: (permittedAction?: KnowledgeBasePermittedAction) => boolean
    isDragging?: boolean
    titleClassName?: string
    selected?: boolean
    showExtensions?: boolean
    extensions?: JSX.Element[]
    isRoot?: boolean
    icon?: IconProp | ((item: TreeItem) => IconProp)
    showContentOnHover?: boolean
}

export const Category: React.FC<CategoryProps> = props => {
    const {
        item,
        onExpand,
        onCollapse,
        isDragging,
        titleClassName,
        onTitleClick,
        onCanEdit,
        selected,
        showExtensions,
        extensions,
        icon,
        isRoot = false,
        showContentOnHover
    } = props

    const { t } = useTranslation()
    const {
        id,
        data: { title = "", catalogCode, symbolCode, permittedAction }
    } = item

    const canEdit = onCanEdit(permittedAction)

    const getToggleIcon = (
        item: TreeItem,
        onExpand: (itemId: ItemId) => void,
        onCollapse: (itemId: ItemId) => void
    ): [JSX.Element, () => void] =>
        item.isExpanded
            ? [
                  <FontAwesomeIcon
                      icon={faAngleRight}
                      size="lg"
                      className="category__arrow category__arrow_expanded"
                  />,
                  () => onCollapse(item.id)
              ]
            : [<FontAwesomeIcon icon={faAngleRight} size="lg" className="category__arrow" />, () => onExpand(item.id)]

    const getIcon = (item: TreeItem) => {
        if (item.hasChildren) {
            const [toggleIcon, toggleCallback] = getToggleIcon(item, onExpand, onCollapse)
            return toggleIcon && toggleCallback && <CategoryIcon icon={toggleIcon} onClick={toggleCallback} />
        }

        const getIconContainer = (icon: IconProp) => (
            <CategoryIcon icon={<FontAwesomeIcon icon={icon} size="lg" className="category__article" />} />
        )

        if (!icon) {
            return getIconContainer(faFileAlt)
        } else if (typeof icon === "function") {
            return getIconContainer(icon(item))
        } else {
            return getIconContainer(icon)
        }
    }

    const handleTitleClick = () => {
        if (onTitleClick) {
            onTitleClick(item, catalogCode, symbolCode)
        }
    }

    const projectId = useAppSelector(selectCurrentProjectId)
    const currentArticle = useSelector(selectCurrentArticle)
    const isOpenedArticle: boolean = `${symbolCode}` === currentArticle?.Article?.SymbolCode

    const [articleContent, setArticleContent] = useState<string | null>(null)

    const fetchArticleContent = useCallback(async () => {
        if (item.data.symbolCode && projectId && articleContent === null && !isRoot) {
            const { Article } = await knowledgeBaseController.getArticle(projectId, item.data.symbolCode)
            const content = Article?.Answers?.[0].Text ?? ""
            setArticleContent(Boolean(Article?.Scenario) ? t(`${tNamespace}scenario`) : content)
        }
    }, [item.data.symbolCode, projectId, articleContent, isRoot])

    const tooltipCondition =
        (showContentOnHover && Boolean(articleContent?.length)) || title.length > categoryTitleMaxLength

    const tooltipContent = showContentOnHover && Boolean(articleContent?.length) ? articleContent : title

    return (
        <div
            className={cn(
                "category",
                selected || isOpenedArticle ? "category_selected" : undefined,
                showExtensions ? "category_extensions-shown" : undefined,
                isDragging ? "category__drag" : undefined,
                isRoot ? "category__root" : undefined
            )}
            data-testid={testId.category}
        >
            {getIcon(item)}

            <TooltipTrigger
                id={id as string}
                placement={showContentOnHover ? "auto" : "top"}
                content={truncate(tooltipContent, { length: 1000, omission: "..." })}
                condition={tooltipCondition}
                delay={tooltipDelay}
            >
                <div
                    className={cn(
                        isOpenedArticle ? "category__title_isOpenedArticle" : "category__title",
                        titleClassName
                    )}
                    onClick={handleTitleClick}
                    onMouseEnter={fetchArticleContent}
                >
                    {title ? title : t(`${tNamespace}untitled`)}
                </div>
            </TooltipTrigger>
            {canEdit && extensions}
        </div>
    )
}

interface CategoryIconProps {
    icon: JSX.Element
    onClick?: () => void
}

const CategoryIcon: React.FC<CategoryIconProps> = props => {
    const { icon, onClick } = props

    return (
        <div className="category__toggle" onClick={onClick}>
            <div className="category__toggle-icon">{icon}</div>
        </div>
    )
}
