import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from "react"
import { FormikProps } from "formik/dist/types"
import { ArticleFilterFormValues } from "../../../models/articleFilter"
import { WithT } from "i18next"
import { Button, CloseButton, Form, FormControl } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import { nameof } from "../../../utility/common/nameof"
import LoadingButton from "../../LoadingButton/LoadingButton"
import { formTranslation } from "../../../locales/form"
import { ArticleFilterFormProps } from "./ArticleFilterForm"
import { useSelector } from "react-redux"
import { selectArticleTypes } from "../../../store/knowledgeBase/selectors"
import { CreatableSelect, Select } from "../../Select/Select"
import { ValueType } from "react-select"
import { OptionType } from "../../AsyncSearchableInput/AsyncSearchableInput"
import Tag from "../../Tag/Tag"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import { FieldArray } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar } from "@fortawesome/pro-light-svg-icons/faCalendar"
import { useModal } from "../../../utility/common/useModal"
import CalendarModal from "../../CalendarModal/CalendarModal"
import format from "date-fns/format"
import ParameterValueControl from "../../ParameterValueControl/ParameterValueControl"
import { datesRangeAsDMYRegex, getStartEndDatesFromString } from "../../../utility/common/formatDate"
import cn from "classnames"

const tNamespace = "knowledgeBase:article-filter."

const defaultArticleTypes = { Types: [] }

const FormikArticleFilterForm: React.FC<ArticleFilterFormProps & FormikProps<ArticleFilterFormValues> & WithT> =
    props => {
        const { t, handleSubmit, setFieldValue, values, touched, setFieldTouched, onCancel } = props

        const filterTypes = [
            { value: "Type", label: t(`${tNamespace}article-type`) },
            { value: "Tags", label: t(`${tNamespace}tags`) },
            {
                value: "ParameterValues",
                label: t(`${tNamespace}article-parameter`)
            },
            { value: "Date", label: t(`${tNamespace}modified-date`) }
        ]

        const articleTypes = useSelector(selectArticleTypes)
        const articleTypeOptions = (articleTypes || defaultArticleTypes).Types.map(Type => ({
            label: Type.Title,
            value: Type.Id
        }))
        const articleTypesMap = useMemo(
            () =>
                (articleTypes || defaultArticleTypes).Types.reduce((obj: { [key: string]: string }, type) => {
                    obj[type.Id] = type.Title
                    return obj
                }, {}),
            [articleTypes]
        )

        const handleSelectFilter = useCallback(
            (option: ValueType<OptionType, false>) => {
                if (option) {
                    switch (option.value) {
                        case "Type":
                            !values[option.value] && setFieldValue(option.value, "", false)
                            break
                        case "Tags":
                            !values[option.value] && setFieldValue(option.value, [], false)
                            break
                        case "ParameterValues":
                            setFieldValue(option.value, [...(values.ParameterValues || []), ""], false)
                            break
                        case "Date":
                            !values.DateFrom && setFieldValue("DateFrom", 0, false)
                            !values.DateTo && setFieldValue("DateTo", 0, false)
                            break
                    }
                }
            },
            [setFieldValue, values]
        )

        const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
            <CalendarModal show={modalOpen} onClose={closeModal} onSubmit={handleDateChange} onExited={onExited} />
        ))

        const handleDateChange = (dates: Date[]) => {
            setIsDateCorrect(true)
            setFieldValue(`DateFrom`, dates[0], false)
            setFieldValue(`DateTo`, dates[1], false)
        }

        const dateInputRef = useRef<HTMLInputElement>(null)
        const [isDateCorrect, setIsDateCorrect] = useState(false)

        const disabledSubmitCondition = values.DateFrom !== undefined && !isDateCorrect

        const handleClearDates = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation()
            if (dateInputRef.current) {
                dateInputRef.current.value = ""
            }
            setIsDateCorrect(false)
            setFieldTouched("DateFrom", false)
            setFieldValue(`DateFrom`, 0, false)
            setFieldValue(`DateTo`, 0, false)
        }

        const handleDateInputChange = (e: ChangeEvent<HTMLInputElement>) => {
            const dates = e.target.value
            const isValidDates = datesRangeAsDMYRegex.test(dates)
            setFieldTouched("DateFrom", true)
            setIsDateCorrect(isValidDates)

            if (isValidDates) {
                const [startDate, endDate] = getStartEndDatesFromString(dates)
                setFieldValue(`DateFrom`, startDate, false)
                setFieldValue(`DateTo`, endDate, false)
            }
        }

        const modifiedDates =
            values.DateFrom && values.DateTo
                ? `${format(values.DateFrom, "dd.MM.yyyy")}-${format(values.DateTo, "dd.MM.yyyy")}`
                : ""

        const handleSelectArticleType = useCallback(
            (option: ValueType<OptionType, false>) => {
                setFieldValue(nameof<ArticleFilterFormValues>("Type"), (option && option.value) || "", false)
            },
            [setFieldValue]
        )

        const handleSelectTag = useCallback(
            (option: ValueType<OptionType, false>) => {
                if (option) {
                    setFieldValue(
                        nameof<ArticleFilterFormValues>("Tags"),
                        [...(values.Tags || []), option.value],
                        false
                    )
                }
            },
            [setFieldValue, values.Tags]
        )

        const handleRemoveTag = useCallback(
            (tagIndex: number) => {
                if (values.Tags) {
                    setFieldValue(
                        nameof<ArticleFilterFormValues>("Tags"),
                        [...values.Tags.filter((_, i) => i !== tagIndex)],
                        false
                    )
                }
            },
            [setFieldValue, values.Tags]
        )

        return (
            <Form className="article-filter-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
                <div className="article-filter-form__content">
                    <Form.Group controlId="filter">
                        <Form.Label>{t(`${tNamespace}add-filter`)}</Form.Label>
                        <Select
                            value={{ label: t(`${tNamespace}select-filter`), value: "" }}
                            isDisabled={!filterTypes.length}
                            options={filterTypes}
                            noOptionsMessage={() => t(formTranslation.noResultsFound)}
                            onChange={handleSelectFilter}
                        />
                    </Form.Group>
                    {values.DateFrom !== undefined && (
                        <Form.Group controlId="filter">
                            <Form.Label>{t(`${tNamespace}modified-date`)}</Form.Label>
                            <div className={"article-filter-form__date"}>
                                <FontAwesomeIcon
                                    icon={faCalendar}
                                    className="article-filter-form__date-input-icon"
                                    onClick={openModal}
                                />
                                <FormControl
                                    onChange={handleDateInputChange}
                                    key={modifiedDates}
                                    defaultValue={modifiedDates}
                                    ref={dateInputRef}
                                    placeholder={t(`${tNamespace}date-placeholder`)}
                                    className={cn(
                                        "article-filter-form__date-input",
                                        touched.DateFrom && !isDateCorrect && "article-filter-form__date-input_invalid"
                                    )}
                                />
                                <CloseButton onClick={handleClearDates} />
                            </div>
                            <div className={cn("article-filter-form__date-validation-hint", "invalid-feedback")}>
                                {t(`${tNamespace}date-validation-hint`)}
                            </div>
                        </Form.Group>
                    )}
                    {values.Type !== undefined && (
                        <Form.Group controlId="filterArticleType">
                            <Form.Label>{t(`${tNamespace}article-type`)}</Form.Label>
                            <Select
                                isClearable
                                value={{
                                    label: values.Type
                                        ? articleTypesMap[values.Type] || ""
                                        : t(`${tNamespace}select-article-type`),
                                    value: values.Type
                                }}
                                isDisabled={!articleTypes || !articleTypes.Types.length}
                                options={articleTypeOptions}
                                noOptionsMessage={() => t(formTranslation.noResultsFound)}
                                onChange={handleSelectArticleType}
                            />
                        </Form.Group>
                    )}
                    {values.Tags !== undefined && (
                        <>
                            <Form.Group controlId="filterTags">
                                <Form.Label>{t(`${tNamespace}tags`)}</Form.Label>
                                <CreatableSelect
                                    className="article-filter-form__tag-input"
                                    value={{ label: t(`${tNamespace}add-tag`), value: "" }}
                                    options={[]}
                                    noOptionsMessage={() => null}
                                    onChange={handleSelectTag}
                                    formatCreateLabel={(inputValue: string) =>
                                        `${t(`${tNamespace}add-tag`)}: ${inputValue}`
                                    }
                                />
                            </Form.Group>
                            <div className="article-filter-form__tags">
                                {values.Tags.map((tag: string, index: number) => (
                                    <Tag
                                        className="article-filter-form__tag"
                                        key={`${tag}-${index}`}
                                        index={index}
                                        title={`#${tag}`}
                                        onDelete={handleRemoveTag}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                    {values.ParameterValues && (
                        <FieldArray
                            name={nameof<ArticleFilterFormValues>("ParameterValues")}
                            render={({ name, remove }) =>
                                values.ParameterValues &&
                                values.ParameterValues.map((_, index) => (
                                    <ParameterValueControl
                                        title={t(`${tNamespace}parameter`)}
                                        onDelete={() => remove(index)}
                                        key={index}
                                    >
                                        <DebouncedValidatableInput
                                            id={`formParameterValues${index}`}
                                            type="text"
                                            name={`${name}.${index}`}
                                        />
                                    </ParameterValueControl>
                                ))
                            }
                        />
                    )}
                </div>
                <div className="article-filter-form__footer">
                    <Button className="article-filter-form__cancel" variant="light" onClick={onCancel}>
                        {t(formTranslation.cancel)}
                    </Button>
                    <LoadingButton
                        className="article-filter-form__submit"
                        variant="primary"
                        type="submit"
                        disabled={disabledSubmitCondition}
                    >
                        {t(formTranslation.apply)}
                    </LoadingButton>
                </div>
            </Form>
        )
    }

export default FormikArticleFilterForm
