import React, { useEffect, useRef, useState } from "react"
import { beautifyMsToHMSString, DAY_IN_MS } from "../../utility/common/time"
import useBeforeUnload from "../../hooks/useBeforeUnload"
import { SelectedOperatorStatus } from "../../models/operatorStatus"
import { useDispatch } from "react-redux"
import { testId } from "../../utility/tests/testId"
import { useTranslation } from "react-i18next"
import { saveStatusTimer } from "../../store/userOperator/actions"

const tNamespace = "operator"

export interface OperatorStatusTimerProps {
    status: SelectedOperatorStatus
}

const OperatorStatusTimer: React.FC<OperatorStatusTimerProps> = ({ status }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation(tNamespace)

    const prevTimer = status.StatusSetTime ?? 0

    const [elapsedTime, setElapsedTime] = useState(prevTimer)
    const initialTime = useRef(Date.now())

    useEffect(() => {
        const updateTimer = () => {
            const ms = Date.now() - initialTime.current + prevTimer
            setElapsedTime(ms)
        }

        const timerId = setInterval(updateTimer, 1000)

        return () => {
            if (timerId) {
                clearInterval(timerId)
            }
        }
    }, [prevTimer])

    useBeforeUnload(() => {
        dispatch(saveStatusTimer(status, elapsedTime))
    })

    return (
        <div data-testid={testId.operatorStatusTimer} data-status-timer={elapsedTime}>
            {elapsedTime > DAY_IN_MS ? t("timer-more-than-day") : beautifyMsToHMSString(elapsedTime)}
        </div>
    )
}

export default OperatorStatusTimer
