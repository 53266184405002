export enum OperatorStatusValueDto {
    Online,
    Offline,
    DoNotRoute
}

export type SelectedOperatorStatusDto = {
    Value: OperatorStatusValueDto
    Description: string
    UpdatedAt: number
    StatusSetTime: number
}
