import { ArticleHistoryElement } from "../../../../models/ArticleHistory"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import { selectCurrentArticle } from "../../../../store/knowledgeBase/selectors"
import { formatDate } from "../../helpers"
import cn from "classnames"
import styles from "../ArticleHistoryItem.module.scss"
import { getArticlePreviousVersion } from "../../../../store/knowledgeBase/thunks"
import UserCard from "../../../UserCard/UserCard"
import ArticleStatus from "../../../ArticleStatus/ArticleStatus"

const tNamespace = "knowledgeBase:"

export interface ArticleHistoryItemProps {
    articleHistoryElement: ArticleHistoryElement
    isLatestVersion?: boolean
}

const ArticleHistoryItem: React.FC<ArticleHistoryItemProps> = props => {
    const { articleHistoryElement, isLatestVersion } = props

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const projectId = useSelector(selectCurrentProjectId)
    const currentArticle = useSelector(selectCurrentArticle)

    const isCurrentVersion = currentArticle && currentArticle.Article?.Id === articleHistoryElement.ArticleId
    const formattedDateRange =
        formatDate(articleHistoryElement.ModifiedDate) +
        (articleHistoryElement.Expire ? " - " + formatDate(articleHistoryElement.Expire) : "")

    const fileStorageUrl =
        articleHistoryElement.UserPicture.length > 0
            ? "/api/dialogs/get_file" + articleHistoryElement.UserPicture
            : undefined

    return (
        <div
            className={cn(styles.ArticleHistoryItem, isLatestVersion ? styles.ArticleHistoryItem_latest : "")}
            onClick={() => {
                if (projectId && !isCurrentVersion) {
                    dispatch(getArticlePreviousVersion(projectId, articleHistoryElement.ArticleId, isLatestVersion))
                }
            }}
        >
            <div className={styles.ArticleHistoryItem__date}>
                {formattedDateRange}
                <div className={cn(styles.ArticleHistoryItem__compare)}>
                    {t(`${tNamespace}article-history.compare`)}
                </div>
            </div>
            {isLatestVersion && (
                <div className={styles.ArticleHistoryItem__current}>
                    {t(`${tNamespace}article-history.current-version`)}
                </div>
            )}
            <div className={styles.ArticleHistoryItem__footer}>
                <UserCard
                    firstName={articleHistoryElement.ModifiedUserFirstname}
                    lastName={articleHistoryElement.ModifiedUserLastname}
                    picture={fileStorageUrl}
                    inHeader={true}
                ></UserCard>
                <ArticleStatus status={articleHistoryElement.Status} />
            </div>
        </div>
    )
}

export default ArticleHistoryItem
