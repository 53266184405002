import { CatalogToDocRequest } from "../../models/knowledgeBaseConverter"
import { apiInstance } from "../instances/apiInstance"
import { DiffUpdateSettingsResponse } from "../../models/projectSettings"

const knowledgeBaseConverterController = {
    catalogToDoc: (projectId: string, request: CatalogToDocRequest): Promise<Blob> =>
        apiInstance
            .post(`${projectId}/knowledge-base-converter/catalog-to-doc`, request, {
                responseType: "blob",
                timeout: 1_800_000
            })
            .then(response => response.data),
    getConvertTemplate: (projectId: string): Promise<Blob> =>
        apiInstance
            .get(`${projectId}/knowledge-base-converter/get-convert-template`, {
                responseType: "blob"
            })
            .then(response => response.data),
    uploadConvertTemplate: (projectId: string, template: File): Promise<DiffUpdateSettingsResponse> => {
        const formData = new FormData()
        formData.append("template", template)

        return apiInstance
            .post(`${projectId}/knowledge-base-converter/upload-convert-template`, formData)
            .then(response => response.data)
    }
}

export default knowledgeBaseConverterController
