import { OperatorStatusValueDto, SelectedOperatorStatusDto } from "../../models/operatorStatusDto"
import { OperatorStatus, OperatorStatusValue, SelectedOperatorStatus } from "../../models/operatorStatus"
import { LocalizedText } from "../../types/localizedText"

const UnknownTitleTranslation: LocalizedText = {
    Value: "menu:statuses.unknown",
    NeedLocalization: true
}
const toOperatorStatusValueModel = (value: OperatorStatusValueDto): OperatorStatusValue => {
    switch (value) {
        case OperatorStatusValueDto.DoNotRoute:
            return OperatorStatusValue.DoNotRoute
        case OperatorStatusValueDto.Offline:
            return OperatorStatusValue.Offline
        case OperatorStatusValueDto.Online:
            return OperatorStatusValue.Online
    }
}

export const toSelectedOperatorStatusModel = (
    dto: SelectedOperatorStatusDto,
    allStatuses: OperatorStatus[]
): SelectedOperatorStatus => {
    const foundedStatus = allStatuses.find(s => s.Description === dto.Description)
    return {
        Value: toOperatorStatusValueModel(dto.Value),
        Description: dto.Description,
        Title: foundedStatus ? foundedStatus.Title : UnknownTitleTranslation,
        UpdatedAt: dto.UpdatedAt,
        StatusSetTime: dto.StatusSetTime
    }
}
